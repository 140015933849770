import React, { useState, useCallback, lazy, Suspense } from 'react'
import { HomeHeroV1 } from '@ashleynexvelsolutions/home-hero-v1'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { Welcome } from '@ashleynexvelsolutions/welcome'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  useSeoData,
  PageSpecificSchemaFunction,
  HomeHeroFunction,
  WelcomeFunction,
  ServicesFunction,
  MaintenancePlanFunction,
  AccoladesFunction,
  MoreBenefitsFunction,
  OffersOurCouponsFunction,
  ReasonsToChooseFunction,
  ReviewsFunction,
  EstimateFunction,
  BrandsFunction,
  RecentBlogsFunction,
  ModalFunction,
  XPinsFunction,
} from '../DataImports'

const Services = lazy(() =>
  import('@ashleynexvelsolutions/services').then(module => ({
    default: module.Services,
  })),
)
const MaintenancePlan = lazy(() =>
  import('@ashleynexvelsolutions/maintenance-plan').then(module => ({
    default: module.MaintenancePlan,
  })),
)
const Accolades = lazy(() =>
  import('@ashleynexvelsolutions/accolades').then(module => ({
    default: module.Accolades,
  })),
)
const MoreBenefits = lazy(() =>
  import('@ashleynexvelsolutions/more-benefits').then(module => ({
    default: module.MoreBenefits,
  })),
)
const OffersOurCoupons = lazy(() =>
  import('@ashleynexvelsolutions/offers-our-coupons').then(module => ({
    default: module.OffersOurCoupons,
  })),
)
const ReasonsToChoose = lazy(() =>
  import('@ashleynexvelsolutions/reasons-to-choose').then(module => ({
    default: module.ReasonsToChoose,
  })),
)
const Reviews = lazy(() =>
  import('@ashleynexvelsolutions/reviews').then(module => ({
    default: module.Reviews,
  })),
)
const Estimate = lazy(() =>
  import('@ashleynexvelsolutions/estimate').then(module => ({
    default: module.Estimate,
  })),
)
const Brands = lazy(() =>
  import('@ashleynexvelsolutions/brands').then(module => ({
    default: module.Brands,
  })),
)
const RecentBlogs = lazy(() =>
  import('@ashleynexvelsolutions/recent-blogs').then(module => ({
    default: module.RecentBlogs,
  })),
)
const Modal = lazy(() =>
  import('@ashleynexvelsolutions/modal').then(module => ({
    default: module.Modal,
  })),
)
const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const XpinsV3 = lazy(() =>
  import('@ashleynexvelsolutions/xpins-v3').then(module => ({ default: module.XpinsV3 })),
)

// eslint-disable-next-line prettier/prettier, no-unused-vars
const Home = ({ data }) => {
  const [formModal, setFormModal] = useState(false)
  const clickFunction = useCallback(() => setFormModal(true), [])
  const clickFunction2 = useCallback(() => setFormModal(false), [])

  return (
    <Layout>
      <HomeHeroV1 {...HomeHeroFunction()} onClick={clickFunction} />
      <Welcome {...WelcomeFunction()} />
      <Suspense fallback="loading">
        <Services {...ServicesFunction()} onClick={clickFunction} />
      </Suspense>
      <Suspense fallback="loading">
        <MaintenancePlan {...MaintenancePlanFunction()} onClick={clickFunction} />
      </Suspense>
      <Suspense fallback="loading">
        <Accolades {...AccoladesFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <MoreBenefits {...MoreBenefitsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <OffersOurCoupons {...OffersOurCouponsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <ReasonsToChoose {...ReasonsToChooseFunction()} onClick={clickFunction} />
      </Suspense>
      <Suspense fallback="loading">
        <Reviews {...ReviewsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Estimate {...EstimateFunction()} onClick={clickFunction} />
      </Suspense>
      <Suspense fallback="loading">
        <Brands {...BrandsFunction()} />
      </Suspense>
      <div className="rfs:pt-24">
        <Suspense fallback="loading">
          <XpinsV3 {...XPinsFunction(data)} />
        </Suspense>
      </div>
      <Suspense fallback="loading">
        <RecentBlogs {...RecentBlogsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Modal {...ModalFunction().modal} isDisplayed={formModal} notDisplayed={clickFunction2} />
      </Suspense>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  {
    wpPage(title: { eq: "Home" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
        areaServedLandingPageSchema
        serviceSchema {
          serviceNameSchema
          linkToRelevantServicePageSchema
        }
        keywordsSchema {
          keywordSchema
        }
        localAwardsSchema {
          localAwardSchema
        }
        latitudeSchema
        longitudeSchema
        relevantExternalPagesSchema {
          relevantExternalPageSchema
        }
      }
      xPins {
        xPins {
          mapCenter {
            latitude
            longitude
          }
          radius
          pins {
            label
            date
            content
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
    wp {
      nexvelSchemaMarkup {
        nexvelschema {
          cities {
            city
          }
          services {
            service
            linkToRelevantPageOnYourWebsite
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Home
export const Head = ({ data }) => <Seo2 {...useSeoData(data)} />
